<template>
  
    <form role="form" @submit="onFormSubmit" name="update-feed">
      <TextField v-bind:value.sync="feedForm.name" id="name" :label="$t('blog.feed.info.name')" :placeholder="$t('blog.feed.info.name_placeholder')" required labelAsColumn />
      <TextField v-bind:value.sync="feedForm.description" id="description" :label="$t('blog.feed.info.description')" :placeholder="$t('blog.feed.info.description_placeholder')" textarea labelAsColumn />
      <div class="socials">
        <div class="facebook">
          <div v-if="feed.socials && feed.socials.facebook && feed.socials.facebook.pages && feed.socials.facebook.pages.length > 0">
            <h3>Connecté à Facebook</h3>
            <ul>
              <li v-for="(page, index) of feed.socials.facebook.pages" :key="'facebook-'+index">
                {{ page.name }}
              </li>
            </ul>
            <button class="btn btn-primary facebook" type="button" @click="onDisconnectFacebook">{{$t('blog.feed.update.disconnect-facebook')}}</button>
          </div>
          <button v-else class="btn btn-primary facebook" type="button" @click="onConnectFacebook">{{$t('blog.feed.update.connect-facebook')}}</button>
        </div>
        <div class="linkedin">
          <div v-if="feed.socials && feed.socials.linkedIn && feed.socials.linkedIn.pages && feed.socials.linkedIn.pages.length > 0"> 
            <h3>Connecté à LinkedIn</h3>
            <ul>
              <li v-for="(page, index) of feed.socials.linkedIn.pages" :key="'linkedin-'+index">
                {{ page.name }}
              </li>
            </ul>
            <button class="btn btn-primary facebook" type="button" @click="onDisconnectLinkedIn">{{$t('blog.feed.update.disconnect-linkedin')}}</button>
          </div>
          <button v-else class="btn btn-primary linkedin" type="button" @click="onConnectLinkedIn">{{$t('blog.feed.update.connect-linkedin')}}</button>
        </div>
      </div>
      <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{$t('blog.feed.update.button')}}</button>
    </form>

</template>

<style>

form[name='update-feed'] .socials {
  margin-bottom: 10px;
}

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import TextField from '@fwk-client/components/panels/input/TextField.vue';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';

import { useFeedAdmin } from '../../../../composables/useFeedAdmin';

export default defineComponent({
  props: {
      feed: {
        type: Object,
        required: true
      }
  },
  components: {
    TextField
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { feeds, selectedFeed, updateFeed, feedForm, connectFacebook, disconnectFacebook, connectLinkedIn, disconnectLinkedIn } = useFeedAdmin(props, context);

    const { feed } = toRefs(props);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    feedForm.feedID = feed.value._id;
    feedForm.name = feed.value.name;
    feedForm.description = feed.value.description ? feed.value.description : '';

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=update-feed] button.update' );
      laddaSubmit = Ladda.create(button!);
    })

    const onConnectFacebook = () => {
      return connectFacebook(feed.value._id);
    }

    const onDisconnectFacebook = () => {
      return disconnectFacebook(feed.value._id);
    }

    const onConnectLinkedIn = () => {
      return connectLinkedIn(feed.value._id);
    }

    const onDisconnectLinkedIn = () => {
      return disconnectLinkedIn(feed.value._id);
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      updateFeed().then((result:any) => {
        laddaSubmit!.stop();
      })        
    }

    return {
      feedForm,
      feed,
      onFormSubmit,
      onConnectFacebook,
      onDisconnectFacebook,
      onConnectLinkedIn,
      onDisconnectLinkedIn
    }
  }
})
</script>