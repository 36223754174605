<template>
  
    <form role="form" @submit="onFormSubmit" name="publish-feed">

      <ul>
        <li>{{$t('blog.feed.info.name')}}: {{ getLocalizedText(selectedFeed.feed.name) }}</li>
      </ul>


      <div class="production-feed">
        <h4>{{$t('blog.feed.publish.production-feed')}}</h4>
        <ul v-if="selectedFeed.productionFeed">
          <li>{{$t('blog.feed.info.name')}}: {{ getLocalizedText(selectedFeed.productionFeed.name) }}</li>
        </ul>
        <span v-if="selectedFeed.productionFeed == null">{{$t('blog.feed.publish.no-production-feed')}}</span>
      </div>
      
      <button class="btn btn-primary ladda-button publish-feed" data-style="zoom-in" type="submit">{{$t('blog.feed.publish.button')}}</button>
    </form>

</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>

<script lang="ts">
import { reactive, toRefs, Ref, ref, defineComponent, PropType, computed, onMounted } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDate } from '@igotweb-node-api-utils/formatter';

import { useFeedAdmin } from '../../../../composables/useFeedAdmin';

export default defineComponent({
  props: {
    
  },
  components: {
      
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { selectedFeed, publishFeedProduction } = useFeedAdmin(props, context);

    var form:any = reactive({
      includePosts : false
    })

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var publishButton:HTMLButtonElement|null = document.querySelector( 'form[name=publish-feed] button.ladda-button.publish-feed' );
      laddaSubmit = Ladda.create(publishButton!);
    })

    const onFormSubmit = (evt:Event) => {
      evt.preventDefault();

      laddaSubmit!.start();

      return publishFeedProduction(form.includePosts).then((response:any) => {
        laddaSubmit!.stop();
      })
    }

    const currentLanguageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
    
    const formatDateTime = (date:Date) => {
      return formatDate(date, currentLanguageCode);
    }

    return {
      formatDateTime,
      selectedFeed,
      onFormSubmit
    }
  }
})
</script>