<template>
  
    <form role="form" @submit="onFormSubmit" name="create-feed">
      <TextField v-bind:value.sync="feedForm.name" id="name" :label="$t('blog.feed.info.name')" :placeholder="$t('blog.feed.info.name_placeholder')" required labelAsColumn />
      <TextField v-bind:value.sync="feedForm.description" id="description" :label="$t('blog.feed.info.description')" :placeholder="$t('blog.feed.info.description_placeholder')" textarea labelAsColumn />
      <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Create</button>
    </form>

</template>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import TextField from '@fwk-client/components/panels/input/TextField.vue';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';

import { useFeedAdmin } from '../../../../composables/useFeedAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      TextField
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { feeds, selectedFeed, createFeed, feedForm } = useFeedAdmin(props, context);

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-feed] button' );
        laddaSubmit = Ladda.create(button!);
      })

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        laddaSubmit!.start();
        
        createFeed().then((created:boolean) => {
          if(created) {  
            context.emit('feed-created');
          }
          laddaSubmit!.stop();
        });
      }
      return { 
        feedForm,
        selectedFeed,
        onFormSubmit
      }
    }
})
</script>