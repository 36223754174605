<template>
  <div class="ibox feeds">
    <div class="ibox-title">
      <h2>{{ $t('blog.feed.list.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="feeds"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isFeedUpdateAllowed(row.item.adminRights.authRoles)">
            <a href="javascript:void(0)" @click="showUpdateModal(row.item.feed)">{{$t('blog.feed.list.options.update')}}</a>
            /
          </span>
          <span v-if="isFeedRemoveAllowed(row.item.adminRights.authRoles)">
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item.feed)">{{$t('blog.feed.list.options.delete')}}</a>
            /
          </span>
          <a href="javascript:void(0)" @click="showFeedPage(row.item.feed)">{{$t('blog.feed.list.options.posts')}}</a>
          /
          <a href="javascript:void(0)" @click="showPublishModal(row.item.feed)">{{$t('blog.feed.list.options.publish')}}</a>
        </template>
      </b-table>

      <button v-if="isFeedCreateAllowed()" class="btn btn-primary" @click="showCreateItemModal()">{{$t('blog.feed.list.create-button')}}</button>

      <b-modal size="lg" ref="updateItemModal" :title="$t('blog.feed.update.title', {feedName: itemToUpdate.name})" hide-footer lazy>
        <UpdateFeed :feed="itemToUpdate" v-on:feed-updated="onItemUpdated" />
      </b-modal>

      <b-modal size="lg" ref="createItemModal" :title="$t('blog.feed.create.title')" hide-footer lazy>
        <CreateFeed v-on:feed-created="onItemCreated" />
      </b-modal>

      <b-modal size="xl" ref="publishItemModal" :no-enforce-focus="true" :title="$t('blog.feed.publish.title', {feedTitle: getLocalizedText(selectedFeed.feed.name)})" hide-footer lazy>
        <Publish />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('blog.feed.list.delete-confirmation', {feedName: itemToRemove.name})}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, ComputedRef, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import UpdateFeed from './Update.vue';
import CreateFeed from './Create.vue';
import Publish from './Publish.vue';
import * as api from '@fwk-client/utils/api';
import { languagesTypes } from '@fwk-client/store/types';

import { useFeedAdmin } from '../../../../composables/useFeedAdmin';

import { types as applicationTypes } from '@root/src/client/store/application';


export default defineComponent({
  props: {
  },
  components: {
    CreateFeed,
    UpdateFeed,
    Publish
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { feeds, selectedFeed, selectFeed, removeFeed, isFeedUpdateAllowed, isFeedCreateAllowed, isFeedRemoveAllowed } = useFeedAdmin(props, context);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);
    const publishItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const itemToRemove:Ref<any> = ref({});
    const itemToUpdate:Ref<any> = ref({});
    const itemToUpdateProd:Ref<any> = ref({});

    const listLoading:Ref<boolean> = ref(false);
    const listItemsSelected:Ref<any[]> = ref([]);

    const listFields = [
        {
          key: "select",
          label: "",
          class: (isMultiSelectAllowed.value ? "" : "hidden"),
        },
        {
          key: "feed.name",
          label: ""
        },
        {
          key: "feed.description",
          label: ""
        },
        {
          key: "options"
        }
      ];

    const numberOfRows:Ref<number> = computed(() => {
        return feeds.value.length
    })

    onMounted(() => {
      listFields[1].label = app.$t('blog.feed.list.headers.name') as string;
      listFields[2].label = app.$t('blog.feed.list.headers.description') as string;
      listFields[3].label = app.$t('blog.feed.list.headers.options') as string;
    })

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemCreated = () => {
      // @ts-ignore
      createItemModal.value.hide()
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
    }
    
    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const showUpdateModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    const showPublishModal = (item:any) => {
      // We first should select the feed
      selectFeed(item._id).then(() => {
        // @ts-ignore
        publishItemModal.value.show()
      })
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

    const showFeedPage = (feed:any) => {
      // We first should select the feed
      selectFeed(feed._id).then(() => {
        // We redirect to the feed dedicated page
        $router.push("/"+currentLanguageCode.value+"/blog/feed").catch((err:any) => {});
      })
    }

    const showDeploymentPage = (feed:any) => {
      // We first should select the feed
      selectFeed(feed._id).then(() => {
        // We redirect to the feed dedicated page
        $router.push("/"+currentLanguageCode.value+"/blog/deployment").catch((err:any) => {});
      })
    }

    const removeItem = () => {
      listLoading.value = true;
      removeFeed(itemToRemove.value._id).then((removed:boolean) => {
        // We reset the user to remove
        itemToRemove.value = {};

        listLoading.value = false;
      })
    }

    return {
      listItems,
      createItemModal,
      removeItemModal,
      updateItemModal,
      isFeedUpdateAllowed, isFeedCreateAllowed, isFeedRemoveAllowed,
      showNumberOfRows,
      numberOfRows,
      listItemsSelected,
      itemToRemove,
      itemToUpdate,
      listLoading,
      feeds,
      listFields,
      onItemCreated,
      onItemUpdated,
      confirmRemoveItem,
      showUpdateModal,
      showCreateItemModal,
      showFeedPage,
      showDeploymentPage,
      removeItem,
      publishItemModal,
      showPublishModal,
      selectedFeed
    }
  }
})
</script>