<template>
  <section id="content" ref="shopsContent">
    <PageTitle :title="$t('blog.admin.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row generate-success">
        <div class="col-lg-12">
          <ListFeeds></ListFeeds>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <Copy></Copy>
        </div>
      </div>

    </div>
  </section>
</template>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';

import ListFeeds from '../../panels/admin/feeds/ListFeeds.vue';
import Copy from '../../panels/admin/posts/Copy.vue';

@Component({
  components: {
    PageTitle,
    ListFeeds,
    Copy
  }
})
export default class Blogs extends mixins(GenericPage) {
  
}
</script>